import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import '../styles/home.css'
import Student from '../images/student.jpg'
import Device from '../images/devices.png'
import Stacked from '../images/stacked-rocks.png'
import Education from '../images/student.svg'
import Tablet from '../images/tablet.svg'
import Teacher from '../images/teacher.svg'
import Student1 from '../images/students1.jpg'
import Student2 from '../images/students2.jpg'
import Student3 from '../images/students3.jpg'
import TreeLogo from '../images/tree-logo.png'
import GoodAlways from '../images/goodalways.png'
import { useState } from "react"

const IndexPage = () => {
  const azucenasURL = 'https://azucenas.org/social-returns'
  const goodalwaysURL = 'https://goodalways.com'

  const [shouldRerender, setShouldRerender] = useState(false);

  React.useEffect(() => {
    // Change shouldRerender to true when you want to force a re-render
    setShouldRerender(true);
    console.log(test())
  }, [/* dependencies */]);
  const test = () => {
    if (typeof window === 'undefined') {
      return goodalwaysURL
      // Server-side rendering logic
    } else {
      const currentUrl = window.location.href;
      if (currentUrl.includes('?r=ga')) {
        // Set the referrer as 'azucenas.org' in localStorage
        localStorage.setItem('referrer', 'goodalways.com');
        // Set the referrer as 'goodalways.com' in sessionStorage
        sessionStorage.setItem('referrer', 'goodalways.com');
        return goodalwaysURL
      }
      const referrer = localStorage.getItem('referrer')
      if (referrer === 'azucenas.org') {
        return azucenasURL
      }
      // Get the current URL
      // Check if the URL contains the query parameter "?referrer=az"
      if (currentUrl.includes('?r=az')) {
        // Set the referrer as 'azucenas.org' in localStorage
        localStorage.setItem('referrer', 'azucenas.org');
        // Set the referrer as 'azucenas.org' in sessionStorage
        sessionStorage.setItem('referrer', 'azucenas.org');
        return azucenasURL
      } else {
        return goodalwaysURL
      }
    }
  }


  return (
    <Layout>
      <Seo title="Home" />

      <section className="banner-home">

        <div className="banner-box">
          <h1>EMPOWER THEIR FUTURE THROUGH EDUCATION </h1>
          <a href="https://checkout.square.site/merchant/WFJDHKBHCFDKF/checkout/3NBHFIZQ4XMHWIJV65M25T54" target="_blank">
            <button>Donate</button>
          </a>
        </div>


      </section>

      <section className="what-we-do">
        <div className="text-box">
          <h2>The Need For <span id="accent">Education</span></h2>
          <p>We provide educational resources for youth in rural parts of Guatemala where access to high quality education is sparse.</p>
          <a href="https://checkout.square.site/merchant/WFJDHKBHCFDKF/checkout/3NBHFIZQ4XMHWIJV65M25T54" target="_blank">
            <button>Donate</button>
          </a>
        </div>
        <div className="image-box">
          <div className="glass-box-1"></div>
          <div className="glass-box-2"></div>
          <img src={Student} alt="Stones of Light Student" />
        </div>
      </section>
      <section className="how">
        <div className="image-box2">
          <div className="glass-box-3"></div>
          <div className="glass-box-4"></div>
          <img src={Device} alt="Stones of Light Student" />
        </div>
        <div className="text-box">
          <h2><span id="accent">Modern</span> Education Platform</h2>
          <p>In conjnction with <strong>Colegio Del Futuro</strong> students recieve access to excellent online courses using a smart phone or tablet.</p>
          <a href="https://colegiodelfuturo.com/" target="_blank">
            <button>Learn More</button>
          </a>
        </div>
      </section>

      <section className="options">
        <h1>Here's how <span id="accent">you</span> can help!</h1>
        <h2>$495 will provide a <span id="accent"> full education package</span> to a child in need:</h2>
        <div className="options-row">
          <div className="option-1">
            <h2>$230</h2>
            <img src={Education} width="100px" alt="icon" />
            <p>Pays for one child's education for an entire year!</p>
            <a href="https://checkout.square.site/merchant/WFJDHKBHCFDKF/checkout/3NBHFIZQ4XMHWIJV65M25T54" target="_blank">

              <button>Donate</button>
            </a>
          </div>
          <div className="option-2">
            <h2>$115</h2>
            <img src={Tablet} width="100px" alt="icon" />
            <p>Provides a smart phone or tablet for a child to access the education platform for 1 year!</p>
            <a href="https://checkout.square.site/merchant/WFJDHKBHCFDKF/checkout/3NBHFIZQ4XMHWIJV65M25T54" target="_blank">
              <button>Donate</button>
            </a>
          </div>
          <div className="option-3">
            <h2>$150</h2>
            <img src={Teacher} width="100px" alt="icon" />
            <p>Pays for one child's access to a certified teacher for 1 year !</p>
            <a href="https://checkout.square.site/merchant/WFJDHKBHCFDKF/checkout/3NBHFIZQ4XMHWIJV65M25T54" target="_blank">

              <button>Donate</button>
            </a>
          </div>
        </div>
      </section>

      <section className="image-row">
        <img id="up-left" src={Student3} alt="Stones of Light student" />
        <img src={Student1} alt="Stones of Light student" />
        <img src={Student2} alt="Stones of Light student" />
      </section>


      <h1 id="other-title">Other Ways To <span id="accent">Help</span></h1>


      <section className="other-ways">
        <div className="other-list">
          {shouldRerender && <div className="way">
            <h2>Impact Investing</h2>
            <p>Invest in tropical hardwood trees that deliver amazing returns: cash on cash, environmental returns, and social impacts. Stones of Light directly benefits from each purchase of tropical hardwood trees.</p>
            <div className="button-icon">
              <a href={test()} target="_blank">
                <button>Learn More</button>
              </a>
              <div className="other-icon">
                <img src={test() === azucenasURL ? 'https://i.imgur.com/px19yim.png' : TreeLogo} alt="Good Always Logo" width="90px" />
              </div>
            </div>
          </div>}
          <div className="way">
            <h2>Artisan Marketplace</h2>
            <p>Purchase products from Good Always where 100% of the profits help combat extreme poverty. Each purchase helps educate children and provide jobs to people who are in need.</p>
            <div className="button-icon">
              <a href='https://goodalways.org' target="_blank">
                <button>Check It Out</button>
              </a>
              <div className="other-icon">
                <img src={GoodAlways} alt="Good Always Logo" width="90px" />
              </div>
            </div>
          </div>
        </div>
        <img className="rocks" src={Stacked} width="300px" alt="stacked rocks" />
      </section>


    </Layout >
  )
}

export default IndexPage
